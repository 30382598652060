.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none !important;
}

.MuiFormControl-marginNormal {
  margin-top: 8px !important;
}

#fixed_movable
  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

.inlineBlock {
  display: inline-block;
}

.pippette_cursor {
  /* cursor: url("/pipette-icon-png-30.png"), auto; */
}

.gmnoprint button {
  display: none !important;
}

.visited {
  color: purple !important;
}

.tooltipHover {
  font-size: 20px !important;
  max-width: 400px;
  min-width: 300px;
  pointer-events: auto !important;
}
.tooltipHover:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.MuiChip-label {
  text-overflow: unset !important;
}

.__react_component_tooltip {
  /* position: absolute !important; */
  /* top: -78px !important;
  left: 150px !important;
  position: relative !important; */
}

/* #tags-control{
  margin-top: 80px;
} */

#babylonJS {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.second-canvas-babylon {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.container {
  display: flex;
  border: 1px solid #ccc;
}

.box {
  flex: 1;
  background-color: lightgreen;
  border: 1px dashed black;
}
